import { Injectable, Inject, Optional } from '@angular/core'
import { LANGUAGE, LanguageService } from '@alliance/shared/models'
import { TranslationLanguageEnum } from '../../types-generated'
import { map, Observable } from 'rxjs'
import { DEFAULT_LANG_LIST } from '../../constants/default-lang-list'
import { transformLangValue } from '../../constants/transform-lang-value'
import { CityDictionaryService } from '../../domain/city-dictionary/city-dictionary.service'
import { CityDictionaryItem } from '../../models/city-dictionary-models'
import { localizedListItem } from '../../constants/localized-list-item'

@Injectable({
  providedIn: 'root'
})
export class CityDictionaryApiService {
  public constructor(private cityDictionaryService: CityDictionaryService, @Optional() @Inject(LANGUAGE) private readonly language: LanguageService | null) {}

  public getCityListDictionary$(activeLang?: TranslationLanguageEnum | null): Observable<CityDictionaryItem[]> {
    const activeLanguage = activeLang ?? transformLangValue(this.language?.activeLanguage)

    return this.cityDictionaryService.getCityDictionaryList$(DEFAULT_LANG_LIST).pipe(
      map(list =>
        list.map(item => ({
          id: item.id,
          value: item.name,
          slug: '',
          districts: (item.districts ?? []).map(district => localizedListItem(district, activeLanguage)),
          microDistricts: (item.microDistricts ?? []).map(microDistrict => localizedListItem(microDistrict, activeLanguage)),
          metroLines: (item.metroLines ?? []).map(metroLine => ({
            ...localizedListItem(metroLine, activeLanguage),
            stations: (metroLine.stations ?? []).map(station => localizedListItem(station, activeLanguage))
          }))
        }))
      )
    )
  }
}
