import { DictionaryService } from '@alliance/jobseeker/api'
import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { forkJoin, Observable, of, zip } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { BreadcrumbList, ListItem } from 'schema-dts'
import { HelpersService } from '../helpers.service'
import { Translations } from '../localization/translations'
import { PartialSeoParamsResponse, PlatformHosts, VacancyListSeoParams } from '../models'
import { AllowedFilterCombinations, AllowedFilters, AllowedIndexFiltersEnum, VacListSeoParams, VacancyListModel } from '../../openapi/model/vacancy-list.model'

@Injectable({ providedIn: 'root' })
export class VacancyListDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private dictionaryService: DictionaryService, private helpersService: HelpersService) {}

  public getParams({ params }: VacancyListSeoParams): Observable<PartialSeoParamsResponse> {
    const { totalItems } = params
    const seoParams = this.getSeoParams(params)

    return this.getBaseParams$(seoParams).pipe(
      switchMap(baseParams =>
        of(seoParams.filters?.keywords).pipe(
          switchMap(value => (value ? this.dictionaryService.getKeywordsWithTransliteration() : of([]))),
          switchMap(list => {
            const isKeywordsNotAllowedForIndexing = list.length && !list.some(item => (item?.name ?? '').toLowerCase() === (seoParams.filters?.keywords ?? '').toLowerCase())
            return isKeywordsNotAllowedForIndexing || !totalItems || !seoParams.combinationKey
              ? of({ ...baseParams, noIndexNoFollow: true })
              : this.getIndexingParams$(params, seoParams.combinationKey).pipe(map(indexingParams => ({ ...baseParams, ...indexingParams })))
          })
        )
      )
    )
  }

  private getBaseParams$(seoParams: VacListSeoParams): Observable<PartialSeoParamsResponse> {
    if (!seoParams.combinationKey) {
      return of({
        h1: [''],
        title: this.translationService.translate(this.translations.vacancyList.default.title),
        description: this.translationService.translate(this.translations.vacancyList.default.description)
      })
    }
    return this.dictionaryService.getCityName$(+(seoParams.filters?.cityId ?? 0), null, true).pipe(
      map(cityName => {
        const { filters } = seoParams
        const translateParams = {
          ...filters,
          totalItems: `${seoParams.totalItems}`,
          cityId: cityName ?? this.translationService.translate(this.translations.vacancyList.ukraine)
        }

        const pageLabel = seoParams?.filters?.page ? this.translationService.translate(this.translations.page, { page: seoParams?.filters?.page }) : ''

        return {
          h1: [this.translationService.translate(this.translations.list[seoParams.combinationKey].h1, translateParams) ?? ''],
          title: (this.translationService.translate(this.translations.list[seoParams.combinationKey].title, translateParams) ?? '') + pageLabel,
          description: (this.translationService.translate(this.translations.list[seoParams.combinationKey].description, translateParams) ?? '') + pageLabel
        }
      })
    )
  }

  private getIndexingParams$(inputParams: VacancyListModel, combinationKey: string): Observable<PartialSeoParamsResponse> {
    const { url } = inputParams
    const path = url.replace(/^\/ru/, '').split('?')[0]

    return this.getJsonLd$(inputParams, combinationKey).pipe(
      map(jsonLd => ({
        canonicalUrl: this.helpersService.createURL(PlatformHosts.desktop, path),
        hrefLang: this.helpersService.createHrefLangURLs(path, path),
        jsonLd: {
          desktop: jsonLd,
          mobile: jsonLd
        }
      }))
    )
  }

  private getSeoParams(input: VacancyListModel): VacListSeoParams {
    const seoParams = this.validateAndFilterParams(input.filters, input.url)

    return {
      combinationKey: this.getMatchingCombination(seoParams),
      totalItems: input.totalItems,
      filters: seoParams
    }
  }

  // Метод для проверки, существует ли комбинация с текущим набором ключей
  private getMatchingCombination(params: AllowedFilters | null): string {
    if (!params) {
      return ''
    }

    // Создаём массив значений, содержащих запятую
    const valuesWithCommas = Object.values(params)
      .filter(value => value.includes(','))
      .map(value => value.split(','))

    // Если хотя бы один из параметров содержит значение с несколькими элементами, то ключ для индексации не создаётся
    if (valuesWithCommas.length > 0) {
      return ''
    }

    const filters = Object.fromEntries(Object.entries(params).filter(([key, value]) => (key !== 'keywords' || value !== '') && key !== 'page'))
    // Получаем ключи параметров, сортируем и создаем строку ключа
    const paramKeys = Object.keys(filters).sort().join('+')
    // Получаем масив разрешенных комбинаций
    const allowedCombinations = AllowedFilterCombinations.map(combination => combination.sort().join('+'))
    return allowedCombinations.includes(paramKeys) ? paramKeys : ''
  }

  public validateAndFilterParams(params: { [key: string]: string }, url: string): AllowedFilters | null {
    if (!this.isValidMatrixParams(url)) {
      return null
    }

    const allowedKeys = Object.values(AllowedIndexFiltersEnum)
    // Проверяем, что все ключи объекта params соответствуют допустимым ключам
    if (!Object.keys(params).every(key => allowedKeys.includes(key as AllowedIndexFiltersEnum))) {
      return null
    }
    // Фильтруем и возвращаем только разрешённые ключи
    return Object.fromEntries(Object.entries(params).filter(([key]) => allowedKeys.includes(key as AllowedIndexFiltersEnum))) as AllowedFilters
  }

  private getJsonLd$(inputParams: VacancyListModel, combinationKey: string): Observable<string> {
    return this.generateBreadcrumbCombinations(inputParams, combinationKey).pipe(
      map(breadcrumbs =>
        this.helpersService.createJsonLd<BreadcrumbList>(PlatformHosts.desktop, {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            this.helpersService.getHomePageBreadcrumb(PlatformHosts.desktop),
            this.helpersService.getSearchByVacanciesBreadcrumb(PlatformHosts.desktop),
            ...breadcrumbs
              .filter((item, index, self) => index === self.findIndex(breadcrumb => breadcrumb.name === item.name))
              .map((item, index) => ({
                ...item,
                position: index + 3
              }))
          ]
        })
      )
    )
  }

  private generateBreadcrumbCombinations(inputParams: VacancyListModel, inputKey: string): Observable<ListItem[]> {
    const { keywords, cityId, ...filters } = inputParams.filters

    const inputKeys = inputKey.split('+')
    const hasCityId = inputKeys.includes(AllowedIndexFiltersEnum.cityId)
    const hasKeywords = inputKeys.includes(AllowedIndexFiltersEnum.keywords)
    const otherKeys = inputKeys.filter(key => ![AllowedIndexFiltersEnum.keywords, AllowedIndexFiltersEnum.cityId].includes(key as AllowedIndexFiltersEnum))

    const addBreadcrumb = (kw: string, city: string, extraFilters: { [key: string]: string }): void => {
      observables.push(this.getBreadcrumbItem(kw, city, inputParams.url, extraFilters))
    }

    const observables: Array<Observable<ListItem>> = []

    if (inputKeys.length === 1 || (hasCityId && !hasKeywords && otherKeys.length)) {
      addBreadcrumb('', cityId, {})
    }

    if (hasCityId && hasKeywords) {
      addBreadcrumb(keywords, '0', {})
      addBreadcrumb(keywords, cityId, {})
      if (otherKeys.length) {
        addBreadcrumb(keywords, cityId, filters)
      }
    }

    if (hasCityId && otherKeys.length && !hasKeywords) {
      addBreadcrumb('', cityId, filters)
    }

    return forkJoin(observables)
  }

  private getBreadcrumbItem(keywords: string, cityId: string, url: string, filters: { [key: string]: string }): Observable<ListItem> {
    const formatParams = Object.entries(filters)
      .filter(([key]) => (key as AllowedIndexFiltersEnum) !== AllowedIndexFiltersEnum.page)
      .map(([key, value]) => ` ${this.translationService.translate(this.translations.jsonLd.breadcrumbs.filter[key as AllowedIndexFiltersEnum] ?? '', { [key]: value })}`)
      .join(' ')

    const formatCity = (cityName: string): string => {
      if (+cityId !== 0) {
        return this.translationService.translate(this.translations.jsonLd.breadcrumbs.city, { cityName })
      }
      if (+cityId === 0 && keywords === '' && Object.keys(filters).length === 0) {
        return this.translationService.translate(this.translations.jsonLd.breadcrumbs.allUkraine)
      }
      return ''
    }

    return zip(this.dictionaryService.getCityName$(+cityId, null, true), this.helpersService.createDesktopVacancyListUrl$(+cityId, keywords)).pipe(
      map(([cityName, path]) => ({
        '@type': 'ListItem',
        name: `${this.translationService.translate(this.translations.jsonLd.breadcrumbs.keyword, { keywords })}${formatCity(cityName)}${formatParams}`,
        item: Object.keys(filters).length > 0 ? this.helpersService.createURL(PlatformHosts.desktop, url) : path
      }))
    )
  }

  private isValidMatrixParams(url: string): boolean {
    const paramsPart = url.split('/params;')[1]
    if (!paramsPart) {
      return true
    } // Если матричных параметров нет, считаем URL валидным
    const allowedParams = Object.values(AllowedIndexFiltersEnum) // Массив допустимых параметров
    return paramsPart.split(';').every(param => {
      const [key] = param.split('=')
      return key && allowedParams.includes(key as AllowedIndexFiltersEnum) // Проверяем, есть ли ключ в массиве
    })
  }
}
