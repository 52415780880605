import { Injectable, Inject, Optional } from '@angular/core'
import { LANGUAGE, LanguageService } from '@alliance/shared/models'
import { SearchKeywordWhereInput, TranslationLanguageEnum } from '../../types-generated'
import { DEFAULT_LANG_LIST } from '../../constants/default-lang-list'
import { transformLangValue } from '../../constants/transform-lang-value'
import { SearchKeywordsDictionaryService } from '../../domain/search-keywords-dictionary/search-keywords-dictionary.service'
import { map, Observable } from 'rxjs'
import { ExtendedDictionaryList } from '../../models/base-dictionary-models'

@Injectable({
  providedIn: 'root'
})
export class SearchKeywordsDictionaryApiService {
  public constructor(private searchKeywordsDictionaryService: SearchKeywordsDictionaryService, @Optional() @Inject(LANGUAGE) private readonly language: LanguageService | null) {}

  public getSearchKeywordsListDictionary$(slug: string, activeLang?: TranslationLanguageEnum | null): Observable<ExtendedDictionaryList> {
    const activeLanguage = activeLang ?? transformLangValue(this.language?.activeLanguage)
    // todo SearchKeywordWhereInput должна проставляться деректива @oneOf но нужна версия графа графа 16.9.0
    const where = { slug: { eq: slug } } as SearchKeywordWhereInput

    return this.searchKeywordsDictionaryService.getSearchKeywordsDictionaryList$(where, DEFAULT_LANG_LIST).pipe(
      map(list =>
        list.map(item => ({
          id: item.name,
          value: (item?.translations ?? []).find(translation => translation.language === activeLanguage)?.name ?? item.name,
          slug: item.slug,
          ru: item.translations.find(translation => translation.language === TranslationLanguageEnum.Russian)?.name ?? item.name,
          ua: item.translations.find(translation => translation.language === TranslationLanguageEnum.Ukrainian)?.name ?? item.name,
          en: item.translations.find(translation => translation.language === TranslationLanguageEnum.English)?.name ?? item.name
        }))
      )
    )
  }
}
