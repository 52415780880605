import { Injectable } from '@angular/core'
import { RxStateService } from '@alliance/shared/models'
import { catchError, map, switchMap } from 'rxjs/operators'
import { iif, Observable, of } from 'rxjs'
import { AuthService } from '@alliance/shared/auth/api'
import {
  AddVacancyToFavoriteGQL,
  AddVacancyToFavoriteMutation,
  DislikeCacheFragmentDoc,
  DislikeVacancyGQL,
  DislikeVacancyMutation,
  FavoriteCacheFragmentDoc,
  PublishedVacancyPageFragment,
  RemoveVacancyFromFavoriteGQL,
  RemoveVacancyFromFavoriteMutation,
  SeekerFavoriteCounterGQL,
  UnDilikeVacancyGQL,
  UnDilikeVacancyMutation
} from '@alliance/jobseeker/domain-gql'
import { LabelTypeEnum, VacancyLabelArrayItem, VacancyLabelData } from '../models/models'
import { log } from '@alliance/shared/logger'
import { ApolloCache, defaultDataIdFromObject } from '@apollo/client/core'

export interface UpdateFavoriteCache {
  cache: ApolloCache<AddVacancyToFavoriteMutation> | ApolloCache<RemoveVacancyFromFavoriteMutation>
  id: string
  isFavorite: boolean
}

export interface UpdateDislikeCache {
  cache: ApolloCache<DislikeVacancyMutation> | ApolloCache<UnDilikeVacancyMutation>
  id: string
  isDisliked: boolean
}

@Injectable({
  providedIn: 'root'
})
export class VacancyService extends RxStateService<{
  favoritesCount: number | null
}> {
  public constructor(
    private authService: AuthService,
    private addVacancyToFavoriteGQL: AddVacancyToFavoriteGQL,
    private removeVacancyFromFavoriteGQL: RemoveVacancyFromFavoriteGQL,
    private dislikeVacancyGQL: DislikeVacancyGQL,
    private unDilikeVacancyGQL: UnDilikeVacancyGQL,
    private seekerFavoriteCounterGQL: SeekerFavoriteCounterGQL
  ) {
    super()

    this.initState({
      favoritesCount: this.authService.token$.pipe(
        switchMap(value =>
          iif(() => !!value && !this.authService.isEmployer, this.seekerFavoriteCounterGQL.fetch().pipe(map(({ data }) => Number(data?.seekerFavoriteVacancies?.totalCount?.value ?? 0))), of(null))
        ),
        catchError(() => of(null))
      )
    })
  }

  public updateFavoriteCache(params: UpdateFavoriteCache): void {
    const { cache, id, isFavorite } = params
    try {
      cache.writeFragment({
        id,
        fragment: FavoriteCacheFragmentDoc,
        data: {
          seekerFavorite: {
            __typename: 'SeekerFavoriteVacancyDetails',
            isFavorite
          }
        }
      })
    } catch (e) {
      // из-за того, что карточка вакансии одна, а выводим мы ее в разных местах
      // через это кеш не обновляется, так как тут указан fragmentName: 'Vacancy', которого может не быть
      // пока сделал так
      log.log({ where: 'jobseeker-new-vacancy-card: UtilsService', category: 'try_catch', message: 'updateCache for PublishedVacanciesItemFragment failed', error: e })
    }
  }

  public updateDislikeCache(params: UpdateDislikeCache): void {
    const { cache, id, isDisliked } = params
    try {
      cache.writeFragment({
        id,
        fragment: DislikeCacheFragmentDoc,
        data: {
          seekerDisliked: {
            __typename: 'SeekerDislikedVacancyDetails',
            isDisliked
          }
        }
      })
    } catch (e) {
      // из-за того, что карточка вакансии одна, а выводим мы ее в разных местах
      // через это кеш не обновляется, так как тут указан fragmentName: 'Vacancy', которого может не быть. пока сделал так
      log.log({ where: 'jobseeker-new-vacancy-card: UtilsService', category: 'try_catch', message: 'updateDislikeCache for PublishedVacanciesItemFragment failed', error: e })
    }
  }

  public addToFavorite(vacancyId: string): Observable<boolean> {
    return this.addVacancyToFavoriteGQL
      .mutate(
        { vacancyId },
        {
          update: (cache, { data }) => {
            const id = defaultDataIdFromObject(data?.seekerFavoriteVacancies?.add?.vacancies?.[0] || {})
            if (id) {
              this.updateFavoriteCache({
                cache,
                id,
                isFavorite: true
              })
            }
          }
        }
      )
      .pipe(
        map(({ data }) => !!(data?.seekerFavoriteVacancies?.add?.vacancies ?? []).find(vacancy => vacancy.id === vacancyId)),
        catchError(e => {
          log.log({ where: 'VacancyService', category: 'api_call_failed', message: 'addToFavorite failed', error: e, vacancyId })
          return of(false)
        })
      )
  }

  public removeToFavorite(vacancyId: string): Observable<boolean> {
    return this.removeVacancyFromFavoriteGQL
      .mutate(
        { vacancyId },
        {
          update: (cache, { data }) => {
            const id = defaultDataIdFromObject(data?.seekerFavoriteVacancies?.remove?.vacancies?.[0] || {})
            if (id) {
              this.updateFavoriteCache({
                cache,
                id,
                isFavorite: false
              })
            }
          }
        }
      )
      .pipe(
        map(({ data }) => !!(data?.seekerFavoriteVacancies?.remove?.vacancies ?? []).find(vacancy => vacancy.id === vacancyId)),
        catchError(e => {
          log.log({ where: 'VacancyService', category: 'api_call_failed', message: 'removeToFavorite failed', error: e, vacancyId })
          return of(false)
        })
      )
  }

  public dislikeVacancy(vacancyId: string): Observable<boolean> {
    return this.dislikeVacancyGQL
      .mutate(
        { vacancyId },
        {
          update: (cache, { data }) => {
            const id = defaultDataIdFromObject(data?.seekerDislikedVacancies?.dislike?.vacancies?.[0] || {})
            if (id) {
              this.updateDislikeCache({
                cache,
                id,
                isDisliked: true
              })
            }
          }
        }
      )
      .pipe(
        map(({ data }) => !!(data?.seekerDislikedVacancies?.dislike?.vacancies ?? []).find(vacancy => vacancy.id === vacancyId)),
        catchError(e => {
          log.log({ where: 'VacancyService', category: 'api_call_failed', message: 'dislikeVacancy failed', error: e, vacancyId })
          return of(false)
        })
      )
  }

  public unDislikeVacancy(vacancyId: string): Observable<boolean> {
    return this.unDilikeVacancyGQL
      .mutate(
        { vacancyId },
        {
          update: (cache, { data }) => {
            const id = defaultDataIdFromObject(data?.seekerDislikedVacancies?.undislike?.vacancies?.[0] || {})
            if (id) {
              this.updateDislikeCache({
                cache,
                id,
                isDisliked: false
              })
            }
          }
        }
      )
      .pipe(
        map(({ data }) => !!(data?.seekerDislikedVacancies?.undislike?.vacancies ?? []).find(vacancy => vacancy.id === vacancyId)),
        catchError(e => {
          log.log({ where: 'VacancyService', category: 'api_call_failed', message: 'unDislikeVacancy failed', error: e, vacancyId })
          return of(false)
        })
      )
  }

  public getSearchTag(data: PublishedVacancyPageFragment | undefined | null): string {
    if (!data) {
      return ''
    }

    return data.keyTagGroups && data.keyTagGroups.length ? data?.keyTagGroups[0]?.name || '' : data.title
  }

  public getVacancyLabel(data: VacancyLabelData): VacancyLabelArrayItem[] {
    return [
      {
        type: LabelTypeEnum.hot,
        show: data.hot
      },
      {
        type: LabelTypeEnum.close,
        show: data.closed
      },
      {
        type: LabelTypeEnum.apply,
        show: !!data?.apply?.isApply,
        date: data?.apply?.date || undefined
      },
      {
        type: LabelTypeEnum.remote,
        show: data.remote
      }
    ]
  }
}
